

const GLOBAL = {
	screen: {
		md: () => window.matchMedia('(max-width: 1180px)').matches
	},
	context: { /* insert django context here */ },

	toastifyDefault: {
		duration: 3000,
		position: "center",
		style: {
			background: "linear-gradient(to right, var(--primary-pure), var(--primary-light))",
		},
	},



}

window.GLOBAL = GLOBAL



